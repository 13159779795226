/** @jsx jsx */
import { jsx, Container, Heading } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import { Layout, SEO } from "../components"
import GetCashOfferForm from "../forms/get-cash-offer"

const imgCSS = {
  height: 200,
  width: 200,
  borderRadius: "50%",
  mx: "auto",
  border: theme => `6px solid ${theme.colors.gray3}`,
  mb: 4,
}

const titleCSS = {
  display: "block",
  fontSize: 3,
  fontWeight: "heading",
  textAlign: "center",
  color: "text",
  textDecoration: "none",
}

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          contacts {
            phone
            email
            address
          }
        }
      }
      callImg: file(name: { eq: "call-us" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      visitImg: file(name: { eq: "visit-us" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      emailImg: file(name: { eq: "email-us" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const { site, callImg, visitImg, emailImg } = data
  const { phone, email, address } = site.siteMetadata.contacts

  return (
    <Layout>
      <SEO title="Contact Us" />
      <Container sx={{ px: 3, py: [4, 5, 100] }}>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr", null, "2fr 1fr"],
            gridGap: [4, 5],
          }}
        >
          <div>
            <Heading
              as="h1"
              sx={{
                fontSize: 5,
                mb: 3,
                textAlign: "center",
              }}
            >
              Contact Us
            </Heading>
            <div
              sx={{
                display: [null, "flex"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <div sx={{ width: ["100%", "50%"], mt: [4, 5] }}>
                <Image
                  fluid={callImg.childImageSharp.fluid}
                  sx={{ ...imgCSS }}
                />
                <a href={`tel:${phone}`} sx={{ ...titleCSS }}>
                  {phone}
                </a>
              </div>
              <div sx={{ width: ["100%", "50%"], mt: [4, 5] }}>
                <Image
                  fluid={emailImg.childImageSharp.fluid}
                  sx={{ ...imgCSS }}
                />
                <a href={`mailto:${email}`} sx={{ ...titleCSS }}>
                  {email}
                </a>
              </div>
              <div sx={{ width: ["100%", "50%"], mt: [4, 5] }}>
                <Image
                  fluid={visitImg.childImageSharp.fluid}
                  sx={{ ...imgCSS }}
                />
                <a href="https://goo.gl/maps/Unm135wNFiooHj2AA"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ ...titleCSS }}
                >
                  <div dangerouslySetInnerHTML={{ __html: address }} />
                </a>
              </div>
            </div>
          </div>
          <div>
            <GetCashOfferForm />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default Contact